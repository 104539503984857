import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Card, CardMedia, CardContent, Typography, List, ListItem, ListItemText, Box, Container, IconButton, TextField, InputAdornment } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useCart } from '../context/CartContext';
import './Products.css';

const Products = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('Todas');
  const [searchTerm, setSearchTerm] = useState('');
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const { addToCart } = useCart();

  useEffect(() => {
    fetch('/products.json')
      .then(response => response.json())
      .then(data => {
        setProducts(data);
        const uniqueCategories = ['Todas', ...new Set(data.map(product => product.category))];
        setCategories(uniqueCategories);
      });
  }, []);

  const filteredProducts = products.filter(product => {
    const matchesCategory = selectedCategory === 'Todas' || product.category === selectedCategory;
    const matchesSearchTerm = product.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesMinPrice = minPrice === '' || product.price >= parseFloat(minPrice);
    const matchesMaxPrice = maxPrice === '' || product.price <= parseFloat(maxPrice);
    return matchesCategory && matchesSearchTerm && matchesMinPrice && matchesMaxPrice;
  });

  return (
    <Container maxWidth="lg" className="products-container">
      <Box mt={20} mb={5}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Buscar productos por nombre"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              size="small"
              sx={{ mb: { xs: 2, md: 0 } }}
            />
          </Grid>
          
          <Grid item xs={6} md={4}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Precio máximo"
              type="number"
              value={maxPrice}
              onChange={(e) => setMaxPrice(e.target.value)}
              size="small"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Precio mínimo"
              type="number"
              value={minPrice}
              onChange={(e) => setMinPrice(e.target.value)}
              size="small"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              sx={{ mb: { xs: 2, md: 0 } }}
            />
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Typography sx={{ fontWeight: 'bold' }}>Categorias</Typography>
          <List className="category-list">
            {categories.map(category => (
              <ListItem
                button
                key={category}
                onClick={() => setSelectedCategory(category)}
                selected={selectedCategory === category}
                sx={{
                  backgroundColor: selectedCategory === category ? '#BFE73C' : 'transparent',
                  '&:hover': {
                    backgroundColor: selectedCategory === category ? '#BFE73C' : 'lightgray',
                  },
                }}
              >
                <ListItemText primary={category} />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Grid container spacing={2}>
            {filteredProducts.map(product => (
              <Grid item xs={12} sm={6} md={4} key={product.id}>
                <Card className="product-card">
                  <Link to={`/products/${product.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <CardMedia
                      component="img"
                      image={product.image}
                      alt={product.name}
                      className="product-image"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" className="product-name">
                        {product.name}
                      </Typography>
                      <Typography variant="body2" className="product-price">
                        ${product.price}
                      </Typography>
                    </CardContent>
                  </Link>
                  <Box mt={2} display="flex" justifyContent="space-between" px={2} pb={2}>
                    <IconButton
                      color="primary"
                      onClick={() => addToCart({ ...product, quantity: 1 })}
                      sx={{ backgroundColor: '#B2D7F5', '&:hover': { backgroundColor: '#ffbedf' } }}
                    >
                      <AddShoppingCartIcon />
                    </IconButton>
                    <IconButton
                      component={Link}
                      to={`/products/${product.id}`}
                      sx={{ backgroundColor: '#b7ff82', '&:hover': { backgroundColor: '#ffbedf' } }}
                    >
                      <OpenInNewIcon />
                    </IconButton>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Products;
