import React, { useState } from 'react';
import { Divider, Container, Typography, TextField, Button, Box, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Afiliaciones = () => {
  const [formValues, setFormValues] = useState({
    email: '',
    name: '',
    city: '',
    phone: '',
    meetingDate: null,
  });

  const [open, setOpen] = useState(false);

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleDateChange = (date) => {
    setFormValues({ ...formValues, meetingDate: date });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, name, city, phone, meetingDate } = formValues;

    if (email && name && city && phone && meetingDate) {
      const data = {
        data: {
          title: "Reunion Afiliación Shelo Nabel Col",
          details: `Detalles de la reunión: 
                    Nombre: ${name}, 
                    Email: ${email}, 
                    Ciudad: ${city}, 
                    Teléfono: ${phone}, 
                    Fecha tentativa de reunión: ${meetingDate.toLocaleDateString()}`
        }
      };

      try {
        const response = await fetch('https://yoxhhopai4.execute-api.us-east-1.amazonaws.com/Dev/afiliacion', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });

        const result = await response.json();

        if (response.ok) {
          setOpen(true);
        } else {
          alert('Ha ocurrido un error, pero nos puedes escribir al whatsapp +57 3022613925');
        }
      } catch (error) {
        alert('Ha ocurrido un error, pero nos puedes escribir al whatsapp +57 3022613925');
      }
    } else {
      alert("Por favor, completa todos los campos.");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 20 }}>
      <Typography variant="h5" align="center" gutterBottom sx={{ color: 'black' }}>
        Hola si deseas comprar los productos con descuento o volverte distribuidora independiente y obtener ganancias te invito a que nos reunamos
      </Typography>
      <Box mt={2} mb={2}>
        <Divider />
      </Box>

      <Typography variant="h5" align="center" gutterBottom sx={{ color: 'black' }}>
        Puedes llenar el siguiente formulario para empezar a acordar una reunión virtual
      </Typography>

      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="email"
              variant="outlined"
              required
              fullWidth
              label="Correo Electrónico"
              value={formValues.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="name"
              variant="outlined"
              required
              fullWidth
              label="Nombre"
              value={formValues.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="city"
              variant="outlined"
              required
              fullWidth
              label="Ciudad"
              value={formValues.city}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="phone"
              variant="outlined"
              required
              fullWidth
              label="Celular/WhatsApp"
              value={formValues.phone}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ borderRadius: 1, p: 2 }}>
              <DatePicker
                selected={formValues.meetingDate}
                onChange={handleDateChange}
                placeholderText="Fecha tentativa reunión"
                dateFormat="dd/MM/yyyy"
                customInput={<TextField fullWidth variant="outlined" required />}
              />
            </Box>
          </Grid>
        </Grid>
        <Box mt={4} display="flex" justifyContent="center">
          <Button type="submit" variant="contained" color="primary" sx={{ backgroundColor: '#4caf50', '&:hover': { backgroundColor: '#388e3c' } }}>
            Enviar
          </Button>
        </Box>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Reunión Tentativa</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Gracias por contactarnos. Pronto nos pondremos en contacto contigo para confirmar la fecha y hora de la reunión.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Afiliaciones;
