import React from 'react';
import { Box, Typography } from '@mui/material';
import WhatsAppButtonComponent from './whatsapp';

const FooterLanding = () => {
  return (
    <Box 
      sx={{
        backgroundColor: '#f8f8f8',
        py: 3,
        textAlign: 'center',
        mt: 4,
        borderTop: '1px solid #ddd',
      }}
    >
        <Box mb={2}>
        <WhatsAppButtonComponent></WhatsAppButtonComponent>
        </Box>
      <Typography variant="body1" sx={{ color: '#000' }}>
        Teléfono de contacto: +57 3022613925
      </Typography>
      <Typography variant="body2" sx={{ color: '#000', mt: 1 }}>
        SheloNabelCol - {new Date().getFullYear()} - Powered by <a href="https://www.ingenniapps.com" target="_blank" rel="noopener noreferrer" style={{ color: '#43b7e5', textDecoration: 'none' }}>IngenniApps.com</a>
      </Typography>
    </Box>
  );
};

export default FooterLanding;
