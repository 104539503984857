import React, { useState, useEffect } from 'react';
import { useCart } from '../context/CartContext';
import PaymentButton from './PaymentButton';
import { TextField, Divider, IconButton, Typography, Select, MenuItem, List, ListItem, ListItemAvatar, ListItemText, Avatar, Box } from '@mui/material';
import { Delete as DeleteIcon, ShoppingCart as ShoppingCartIcon, Email as EmailIcon, Home as HomeIcon, Phone as PhoneIcon, LocationCity as LocationCityIcon } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';

const Cart = ({ onClose }) => {
  const { cart, getTotal, removeFromCart, updateQuantity, purchase } = useCart();
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerAddress, setCustomerAddress] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [customerCity, setCustomerCity] = useState('');
  const shippingCost = 15000;

  // Función para cargar los datos desde localStorage
  useEffect(() => {
    const storedEmail = localStorage.getItem('customerEmail');
    const storedAddress = localStorage.getItem('customerAddress');
    const storedPhone = localStorage.getItem('customerPhone');
    const storedCity = localStorage.getItem('customerCity');
    if (storedEmail) setCustomerEmail(storedEmail);
    if (storedAddress) setCustomerAddress(storedAddress);
    if (storedPhone) setCustomerPhone(storedPhone);
    if (storedCity) setCustomerCity(storedCity);
  }, []);

  // Funciones para actualizar el localStorage cuando cambian los datos
  const handleEmailChange = (e) => {
    setCustomerEmail(e.target.value);
    localStorage.setItem('customerEmail', e.target.value);
  };

  const handleAddressChange = (e) => {
    setCustomerAddress(e.target.value);
    localStorage.setItem('customerAddress', e.target.value);
  };

  const handlePhoneChange = (e) => {
    setCustomerPhone(e.target.value);
    localStorage.setItem('customerPhone', e.target.value);
  };

  const handleCityChange = (e) => {
    setCustomerCity(e.target.value);
    localStorage.setItem('customerCity', e.target.value);
  };

  const handleQuantityChange = (productId, quantity) => {
    const intQuantity = parseInt(quantity, 10);
    if (intQuantity > 0) {
      updateQuantity(productId, intQuantity);
    }
  };

  const totalWithoutShipping = parseFloat(getTotal());
  const shippingCostToApply = totalWithoutShipping >= 200000 ? 0 : shippingCost;
  const totalWithShipping = totalWithoutShipping + shippingCostToApply;

  return (
    <Box className="cart-aside" mb={100} sx={{ p: 2, backgroundColor: '#f9f9f9', borderRadius: 2, boxShadow: 3 }}>
      <IconButton onClick={onClose} style={{ float: 'right' }}>
        <CloseIcon />
      </IconButton>
      <Typography variant="h5" component="h2" gutterBottom>
        <ShoppingCartIcon sx={{ verticalAlign: 'middle', marginRight: '8px', color: 'black' }} />
        Carro de compras
      </Typography>
      {cart.length === 0 ? (
        <Typography variant="body1">Tu carro está vacío</Typography>
      ) : (
        <List>
          {cart.map((item, index) => (
            <ListItem key={index} alignItems="flex-start">
              <Box mr={2} mt={2}>
                <ListItemAvatar>
                  <Avatar src={item.image} alt={item.name} variant="square" sx={{ width: 56, height: 56 }} />
                </ListItemAvatar>
              </Box>
              <ListItemText
                primary={item.name}
                secondary={
                  <>
                    <Typography variant="body2">${item.price.toFixed(2)}</Typography>
                    <Select
                      value={item.quantity}
                      onChange={(e) => handleQuantityChange(item.id, e.target.value)}
                      variant="outlined"
                      size="small"
                      sx={{ mt: 1 }}
                    >
                      {[...Array(100)].map((_, i) => (
                        <MenuItem key={i + 1} value={i + 1}>{i + 1}</MenuItem>
                      ))}
                    </Select>
                  </>
                }
              />
              <Box mt={3}>
                <IconButton onClick={() => removeFromCart(item.id)} edge="end">
                  <DeleteIcon />
                </IconButton>
              </Box>
            </ListItem>
          ))}
        </List>
      )}
      <Box mt={2} mb={2}> 
        <Divider />
      </Box>
      
      {cart.length > 0 && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="body1" sx={{ mt: 2 }}>Costo subtotal: ${totalWithoutShipping} COP</Typography>
      <Typography variant="body1">Costo envío: ${shippingCostToApply} COP</Typography>
      <Typography variant="h6" sx={{ mt: 2, color: "black" }}>Costo total con envío:</Typography>
      <Typography variant="h5" sx={{ mt: 2, color: "#43b7e5" }} gutterBottom>${totalWithShipping} COP</Typography>
      <Box mt={2} mb={2}> 
        <Divider />
      </Box>
          <Typography variant="h6" component="h2" gutterBottom>Datos para envío</Typography>
          <TextField
            label="Email"
            type="email"
            value={customerEmail}
            onChange={handleEmailChange}
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: <EmailIcon position="start" />,
            }}
          />
          <TextField
            label="Celular Colombia"
            type="tel"
            value={customerPhone}
            onChange={handlePhoneChange}
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: <PhoneIcon position="start" />,
            }}
          />
          <TextField
            label="Ciudad y Departamento de entrega"
            type="text"
            value={customerCity}
            onChange={handleCityChange}
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: <LocationCityIcon position="start" />,
            }}
          />
          <TextField
            label="Dirección exacta de entrega"
            type="text"
            value={customerAddress}
            onChange={handleAddressChange}
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: <HomeIcon position="start" />,
            }}
          />
          <Box mt={2} marginBottom={10}>
            <PaymentButton
              cart={cart}
              total={totalWithShipping}
              customerEmail={customerEmail}
              customerAddress={customerAddress}
              customerPhone={customerPhone}
              customerCity={customerCity}
              disabled={cart.length === 0}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Cart;


