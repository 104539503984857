import React from 'react';
import Button from '@mui/material/Button';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { styled } from '@mui/system';

const WhatsAppButton = styled(Button)`
  background-color: #b7ff82;
  color: black;
  font-size: 0.8rem; 
  text-transform: none;
`;

const WhatsAppButtonComponent = () => {
  const handleWhatsAppClick = () => {
    // Lógica para abrir WhatsApp o WhatsApp Web según el dispositivo
    const phoneNumber = '573022613925'; // Reemplaza con el número de teléfono deseado
    let url = '';

    const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    const isTablet = /iPad/i.test(navigator.userAgent);

    if (isMobile) {
      url = `whatsapp://send?phone=${phoneNumber}`;
    } else if (isTablet) {
      url = `https://web.whatsapp.com/send?phone=${phoneNumber}`;
    } else {
      url = `https://web.whatsapp.com/send?phone=${phoneNumber}`;
    }

    window.open(url, '_blank');
  };

  return (
    <WhatsAppButton
      variant="contained"
      startIcon={<WhatsAppIcon />}
      onClick={handleWhatsAppClick}
      size="large"
    >
      ¿Tienes Preguntas? Escríbenos 
    </WhatsAppButton>
  );
};

export default WhatsAppButtonComponent;
