import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';

const PaymentButton = ({ cart, total, customerEmail, customerAddress, customerPhone, customerCity }) => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    const checkScript = setInterval(() => {
      if (window.ePayco && window.ePayco.checkout) {
        // Definir la función onTokenReceived si no está definida
        if (!window.ePayco.checkout.onTokenReceived) {
          window.ePayco.checkout.onTokenReceived = (token) => {
            console.log("Token recibido:", token);
          };
        }
        setIsScriptLoaded(true);
        clearInterval(checkScript);
      }
    }, 100);

    return () => clearInterval(checkScript);
  }, []);

  const handlePayment = () => {
    if (isScriptLoaded) {
      try {
        const handler = window.ePayco.checkout.configure({
          key: 'a0a604b036e293ee1f8cc59e02858f53', // Tu llave pública
          test: true, // Cambiar a false para producción
          external: 'false',
        });

        // Concatenar los productos y sus cantidades en la descripción
        const productsDescription = cart.map(item => `${item.name} (Cantidad: ${item.quantity})`).join(', ');

        const data = {
          // Parámetros de la compra
          name: "Compra en tienda",
          description: productsDescription,
          invoice: String(Date.now()), // Generar un número de factura único
          currency: "cop",
          amount: total,
          tax_base: "0",
          tax: "0",
          country: "CO",
          lang: "es",

          // Información del cliente
          name_billing: customerEmail,
          address_billing: `${customerAddress}, ${customerCity}`, // Combina dirección y ciudad
          type_doc_billing: "cc", // O el tipo de documento correspondiente
          number_doc_billing: "123456789", // Número de documento del cliente
          mobilephone_billing: customerPhone, // Teléfono del cliente
          email_billing: customerEmail,

          // URLs de respuesta y confirmación
          response: "https://tu-sitio.com/respuesta",
          confirmation: "https://tu-sitio.com/confirmacion",

          // Métodos de pago habilitados
          methods: ["TDC", "PSE", "SP", "CASH", "DP"]
        };

        handler.open(data);
      } catch (error) {
        console.error('Error al abrir el handler de ePayco:', error);
      }
    } else {
      console.error('ePayco no está definido o el script no está completamente cargado');
    }
  };

  return (
    <Button
      onClick={handlePayment}
      disabled={!isScriptLoaded}
      sx={{
        width: '100%',
        marginTop: '16px',
        padding: '12px 20px',
        backgroundColor: '#8bc440',
        color: '#FFF',
        '&:hover': {
          backgroundColor: '#0056b3',
        },
        '&:disabled': {
          backgroundColor: '#c0c0c0',
        },
      }}
    >
      Pagar con ePayco
    </Button>
  );
};

export default PaymentButton;







