import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
    // También puedes registrar el error en un servicio de reporte de errores
    console.error("Error capturado por ErrorBoundary: ", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>Ocurrió un error inesperado.</h1>
          <button onClick={() => this.setState({ hasError: false })}>
            Volver a intentar
          </button>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;


