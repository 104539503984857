import React, { useState, useEffect } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import Home from './pages/Home';
import Products from './pages/Products';
import ProductDetail from './pages/ProductDetail';
import { CartProvider, useCart } from './context/CartContext';
import Cart from './components/Cart';
import { AppBar, Toolbar, Typography, Box, IconButton, Badge, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import useMediaQuery from '@mui/material/useMediaQuery';
import './App.css';
import FooterLanding from './components/Footing';
import Afiliaciones from './pages/Afiliaciones';

const CartButton = ({ onClick }) => {
  const { cart } = useCart();
  const cartCount = cart.reduce((total, item) => total + item.quantity, 0);

  return (
    <IconButton onClick={onClick} style={{ color: '#ADD8E6' }}>
      <Badge badgeContent={cartCount} color={cartCount > 0 ? 'secondary' : 'default'}>
        <ShoppingCartIcon style={{ color: cartCount > 0 ? '#FF69B4' : '#B0B0B0' }} />
      </Badge>
    </IconButton>
  );
};

const App = () => {
  const [isCartVisible, setCartVisible] = useState(false);
  const [topBarMessage, setTopBarMessage] = useState('');
  const [topBarColors, setTopBarColors] = useState([]);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');

  useEffect(() => {
    fetch('/textsweb.json')
      .then(response => response.json())
      .then(data => {
        if (data.banner) {
          setTopBarMessage(data.banner.topBarMessage || '');
          setTopBarColors(data.banner.topBarColors || []);
        }
      })
      .catch(error => console.error('Error fetching the JSON:', error));
  }, []);



  const showCart = () => {
    setCartVisible(true);
  };

  return (
    <CartProvider>
      <Box className={`main-container ${isCartVisible ? 'shift-content' : ''}`}>
        <AppBar position="fixed" className={`top-bar`} elevation={0}>
          <Toolbar>
            <Typography variant="body2" component="div" className="app-bar-text">
              {topBarMessage}
            </Typography>
          </Toolbar>
        </AppBar>
        <AppBar position="fixed" className={`app-bar ${isCartVisible ? 'app-bar-shift' : ''}`} elevation={0} style={{ top: '30px', backgroundColor: '#ffffff' }}>
          <Toolbar className="toolbar">
            <img src="https://shelonabelcol-public.s3.amazonaws.com/Logo-Empresaria-Independiente.png" alt="Logo" className="menu-logo" />
            <Typography variant="h5" component="div" className="title">
              Shelo Nabel<br />Colombia
            </Typography>
            <Box className="nav-links">
              {!isMobile && (
                <>
                  <Link to="/">Home</Link>
                  <Link to="/afiliaciones">Afiliaciones</Link>
                  <Link to="/products">Productos</Link>
                  
                </>
              )}
              <CartButton onClick={() => setCartVisible(!isCartVisible)} />
            </Box>
            {isMobile && (
              <Box ml={3}>
                <IconButton edge="end" color="inherit" aria-label="menu" onClick={() => setDrawerOpen(true)} className="hamburger-menu">
                  <MenuIcon />
                </IconButton>
              </Box>
            )}
          </Toolbar>
        </AppBar>
        <Drawer anchor="left" open={isDrawerOpen} onClose={() => setDrawerOpen(false)}>
          <Box ml={5} mr={5}>
            <List>
              <ListItem button component={Link} to="/" onClick={() => setDrawerOpen(false)}>
                <ListItemText primary="Home" />
              </ListItem>
              <ListItem button component={Link} to="/products" onClick={() => setDrawerOpen(false)}>
                <ListItemText primary="Productos" />
              </ListItem>
              <ListItem button onClick={() => setCartVisible(!isCartVisible)}>
                <ListItemText primary="Carro de compras" />
              </ListItem>
              <ListItem button component={Link} to="/afiliaciones" onClick={() => setDrawerOpen(false)}>
                <ListItemText primary="Afiliaciones" />
              </ListItem>
            </List>
          </Box>
        </Drawer>
        <Box className={`content ${isCartVisible ? 'content-shift' : ''}`}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/products/:id" element={<ProductDetail showCart={showCart} />} />
            <Route path="/afiliaciones" element={<Afiliaciones />} />
          </Routes>
          {isCartVisible && <Cart onClose={() => setCartVisible(false)} />}
        </Box>
      </Box>
      <FooterLanding></FooterLanding>
    </CartProvider>
  );
};

export default App;

