import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import { Grid, Box, Typography, Button, Container, Paper, Select, MenuItem } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalMallIcon from '@mui/icons-material/LocalMall';

const ProductDetail = ({ showCart }) => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const { addToCart, purchase } = useCart();

  useEffect(() => {
    fetch('/products.json')
      .then(response => response.json())
      .then(data => {
        const foundProduct = data.find(prod => prod.id === parseInt(id));
        setProduct(foundProduct);
      });
  }, [id]);

  if (!product) return <div>Loading...</div>;

  const handleBuyNow = () => {
    addToCart({ ...product, quantity });
    purchase();
    showCart();
  };

  return (
    <Box>
      <Box sx={{ backgroundColor: product.backgroundColor, minHeight: '60vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Paper elevation={0} sx={{ p: 0, backgroundColor: product.backgroundColor, boxShadow: 'none' }}>
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={6}>
                <Carousel indicators={true} navButtonsAlwaysVisible={true} autoPlay={false}>
                  {product.images.map((media, index) => (
                    <Box key={index} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {media.type === 'image' ? (
                        <img src={media.url} alt={product.name} style={{ maxHeight: '700px', maxWidth: '100%' }} />
                      ) : (
                        <video controls style={{ maxHeight: '700px', maxWidth: '100%' }}>
                          <source src={media.url} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      )}
                    </Box>
                  ))}
                </Carousel>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ textAlign: 'left' }}>
                  <Typography variant="h3" gutterBottom>{product.name}</Typography>
                  <Box mb={4}>
                    <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: "black" }}>
                      ${product.price} COP
                    </Typography>
                  </Box>
                  <Box mb={2}>
                    <Typography variant="body1">Descripción:</Typography>
                  </Box>
                  <Typography variant="body1" paragraph>
                    {product.description.paragraphs.map((paragraph, index) => (
                      <span key={index}>{paragraph}<br /></span>
                    ))}
                  </Typography>
                  <Typography variant="body1">Cantidad:</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
                    <Box sx={{ backgroundColor: 'white', padding: 0.5, borderRadius: 1 }}>
                      <Select
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        variant="outlined"
                        size="small"
                        sx={{ width: 80 }}
                      >
                        {[...Array(100)].map((_, i) => (
                          <MenuItem key={i + 1} value={i + 1}>{i + 1}</MenuItem>
                        ))}
                      </Select>
                    </Box>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: '#43b7e5',
                        '&:hover': { backgroundColor: '#BCF6CE' },
                        '&:active': { backgroundColor: '#94b92e' },
                        color: '#000',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                      onClick={() => addToCart({ ...product, quantity })}
                    >
                      <ShoppingCartIcon sx={{ mr: 1 }} /> Add to Cart
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: '#B2D7F5',
                        '&:hover': { backgroundColor: '#BCF6CE' },
                        '&:active': { backgroundColor: '#94b92e' },
                        color: '#000',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                      onClick={handleBuyNow}
                    >
                      <LocalMallIcon sx={{ mr: 1 }} /> Buy Now
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </Box>

      {/* Renderizar las secciones dinámicamente */}
      {product.sections && product.sections.map((section, index) => (
        <Box key={index} sx={{ background: section.gradient || section.backgroundColor, py: 4 }}>
          <Container maxWidth="lg" sx={{ py: 4 }}>
            <Paper elevation={0} sx={{ p: 4, background: section.gradient || section.backgroundColor, color: section.textColor, boxShadow: 'none' }}>
              <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={6} order={{ xs: 2, md: index % 2 === 0 ? 2 : 1 }}>
                  {section.media.type === 'image' ? (
                    <img src={section.media.url} alt="section media" style={{ maxHeight: '400px', maxWidth: '100%' }} />
                  ) : (
                    <video controls style={{ maxHeight: '400px', maxWidth: '100%' }}>
                      <source src={section.media.url} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </Grid>
                <Grid item xs={12} md={6} order={{ xs: 1, md: index % 2 === 0 ? 1 : 2 }}>
                  <Box>
                    {section.text.title && (
                      <Typography variant="h4" gutterBottom>{section.text.title}</Typography>
                    )}
                    <Typography variant="body1">{section.text.content}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </Box>
      ))}

      <Box sx={{ textAlign: 'center', backgroundColor: 'white', py: 4 }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#43b7e5',
            '&:hover': { backgroundColor: '#BCF6CE' },
            '&:active': { backgroundColor: '#94b92e' },
            color: '#000',
            display: 'flex',
            alignItems: 'center',
            mx: 'auto'
          }}
          onClick={handleBuyNow}
        >
          <LocalMallIcon sx={{ mr: 1 }} /> Buy Now
        </Button>
      </Box>
    </Box>
  );
};

export default ProductDetail;
